<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="8"
      >
        <h1>Contrato</h1>
        <v-form @submit.prevent="submit()">
          <v-text-field
            v-model="contratos.versao"
            label="Versao"
            name="versao"
            type="tel"
            required
            :rules="rules"
            disabled
          />
          <v-autocomplete
            v-model="contratos.tipoContratoId"
            label="Tipo de Contrato"
            :items="tiposContrato"
            item-text="nome"
            item-value="tipoContratoId"
            :rules="rules"
          />
          <tiptap-vuetify
            v-model="contratos.texto"
            :extensions="extensions"
          />
          <v-btn
            color="primary"
            type="submit"
          >
            Salvar
          </v-btn>
          <v-btn
            v-show="this.$route.params.id"
            color="error"
            outlined
            @click="destroy()"
          >
            Deletar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogDelete"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          O contrato será deletado. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialogDelete = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUpdate"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          O contrato será atualizado da versão <strong>{{ contratos.versao }}</strong> para a versão <strong>{{ contratos.versao + 1 }}</strong>.<br> Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialogUpdate = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmUpdate()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    components: {
      TiptapVuetify,
    },
    data: () => ({
      dialogDelete: false,
      dialogUpdate: false,
      contratos: {},
      tiposContrato: [],
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    }),
    mounted () {
      this.getTiposContrato()
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/contratos/${this.$route.params.id}`)
          .then(resp => {
            this.contratos = resp.data
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      getTiposContrato () {
        this.$http.get('/tiposcontrato/')
          .then(resp => {
            this.tiposContrato = resp.data.rows
            var versao = this.contratos.versao === undefined ? 1 : this.contratos.versao
            this.contratos.versao = versao
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        if (this.$route.params.id) {
          this.dialogUpdate = true
        } else {
          this.post()
        }
      },
      getFormData () {
        const formData = new FormData()
        formData.append('tipoContratoId', this.contratos.tipoContratoId)
        formData.append('texto', this.contratos.texto)
        formData.append('versao', this.contratos.versao)
        return formData
      },
      post () {
        this.$http.post('/contratos', this.getFormData(), { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/contratos')
          })
          .catch((e) => {
            var err = e.response.status === 409 ? e.response.data.mensagem : 'Falha ao realizar operação'
            this.$toast.error(err)
          })
      },
      put () {
        this.$http.put('/contratos/', this.contratos)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/contratos')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      destroy () {
        this.dialogDelete = true
      },
      confirmDelete () {
        this.$http.delete(`/contratos/${this.$route.params.id}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialogDelete = false
            this.$router.go(-1)
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      confirmUpdate () {
        this.put()
      },
    },
  }
</script>

<style>
.tiptap-vuetify-editor__content {
  height: 300px;
}
</style>
